<!-- 疑问解答 -- 详情 -->
<template>
  <div v-if="dataCont" style="background: rgba(247, 247, 247, 1);padding-right: 40rem">
    <div style="margin: 20rem 0 20rem 20rem">
      <span style="font-size: 14rem;margin-right: 20rem">问题状态</span>
      <i style="font-size: 18rem;font-weight: bold">{{ dataCont.status_describe }}</i>
    </div>

    <div class="title">
      <div v-if="dataCont.basis" class="title-bottom flex flex-wrap">
        <div class="box box-1"><span>学生姓名</span>{{ dataCont.basis.student_name }}</div>
        <div class="box box-1"><span>学号</span>{{ dataCont.basis.student_no }}</div>
        <div class="box box-1"><span>提问科目</span>{{ dataCont.basis.subject_name }}</div>
        <div class="box box-1"><span>答疑教师</span>{{ dataCont.basis.teacher_name }}</div>
        <div class="box box-1"><span>教学学科</span>{{ dataCont.basis.teacher_subject_name }}</div>
        <div class="box box-1"><span>班级</span>{{ dataCont.basis.class_name }}</div>
        <div class="box box-1"><span>年级</span>{{ dataCont.basis.grade_name }}</div>
        <div class="box box-1"><span>校区</span>{{ dataCont.basis.school_name }}</div>
      </div>
    </div>

    <div class="title">
      <div class="title-title">
        <div style="margin-top: 19rem;margin-left: 24rem">问题内容</div>
      </div>
      <div class="title-bottom">
        <div v-if="dataCont.question" class="describe-box">{{ dataCont.question }}</div>
        <t-result v-else style="padding: 20rem 0"></t-result>
      </div>
    </div>

    <div class="title" v-if="status != 5">
      <div class="title-title">
        <div style="margin-top: 19rem;margin-left: 24rem">解决方案</div>
      </div>
      <div class="title-bottom">
        <template v-if="status == 1">
          <div class="flex flex-align" style="line-height: 26rem">
            <span style="font-size: 14rem;margin-right: 20rem">是否给予解决</span>
            <el-radio v-model="radio"
                      v-for="item in radioConfig"
                      @change="checkStatus"
                      :label="item.label">{{ item.text }}
            </el-radio>
          </div>
          <p class="f-14 mt-20">{{ radio == 1 ? '问题解决方案' : '不予解决原因' }}<span class="f-14 c6">（必填）</span>：</p>
          <template v-if="radio == 1">
            <div style="border: 1rem solid #ccc;margin-top: 20rem">
              <Toolbar
                  :defaultConfig="toolbarConfig"
                  :editor="editor"
                  :mode="mode"
                  style="border-bottom: 1rem solid #ccc"
              />
              <Editor
                  v-model="content"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onChange="richChange"
                  style="height: 500rem; overflow-y: hidden;"
                  @onCreated="onCreated"
              />
            </div>
            <p class="f-16 f-bold mt-20 mb-20">关联知识点 <span class="f-14 c6">（必填）</span>：</p>
            <div class="knowledge" v-for="(item, index) in formData.course" :key="index">
              <div class="knowledge_box">
                <el-input v-model='item.category' maxlength="100" placeholder='请输入知识点分类' @input="checkStatus"></el-input>
                <el-input v-model='item.content' maxlength="100" placeholder='请输入知识点内容' @input="checkStatus"></el-input>
              </div>
              <!-- 关联知识点新增删除题目按钮 -->
              <div class="add_knowledge">
                <el-button v-if="formData.course.length > 1" icon="el-icon-delete" circle
                           @click="deleteKnowledge(index)"></el-button>
                <el-button
                    icon="el-icon-plus" circle
                    @click="addKnowledge"
                    v-if="formData.course.length - 1 == index && formData.course.length < 10"></el-button>
              </div>
            </div>
          </template>
          <template v-if="radio == 2">
            <div style="border: 1rem solid #ccc;margin-top: 20rem">
              <el-input
                  type="textarea"
                  v-model="content1"
                  @input="checkStatus"
                  :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
            </div>
          </template>
        </template>
        <template v-if="status == 2 || status == 4">
          <div>
            <p class="f-14 f-bold mb-20" style="padding-top: 10rem">问题解决方案：</p>
            <div v-if="dataCont.solve" class="rich-box" v-html="dataCont.solve"></div>
            <t-result v-else style="padding: 20rem 0"></t-result>
          </div>
          <div>
            <p class="f-14 f-bold mt-20">关联知识点：</p>
            <div>
              <div v-for="item in dataCont.points">
                <span class="span3">{{ item }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="title" v-if="status == 4">
      <div class="title-title">
        <div style="margin-top: 19rem;margin-left: 24rem">学生反馈</div>
      </div>
      <div class="title-bottom">
        <div style="margin-bottom: 12rem">
          <p class="f-14 f-bold">是否解决：<span style="font-weight: normal">{{ dataCont.solve_status_describe }}</span></p>
        </div>
        <template v-if="dataCont.feedback">
          <div class="describe-box">{{ dataCont.feedback }}</div>
        </template>
        <template v-else>
          <div style="text-align: center;padding-bottom: 30rem">
            <img src="../../../../assets/img/good.png" alt="">
            <p style="font-size: 14rem">已经解决了一个问题，给你点个赞哦~~</p>
          </div>
        </template>
      </div>
    </div>

    <div class="title" v-if="status == 5">
      <div class="title-title">
        <div style="margin-top: 19rem;margin-left: 24rem">不予解决原因</div>
      </div>
      <div class="title-bottom">
        <div v-if="dataCont.no_solve" class="describe-box">{{ dataCont.no_solve }}</div>
        <t-result v-else style="padding: 20rem 0"></t-result>
      </div>
    </div>

    <div class="title">
      <div class="title-title">
        <div style="margin-top: 19rem;margin-left: 24rem">操作记录</div>
      </div>
      <div class="title-bottom">
        <template v-if="dataCont.operation_records && dataCont.operation_records.length">
          <div v-for="item in dataCont.operation_records">
            <div v-for="_item in item" style="display: inline-block;margin-right: 60rem;margin-top: 12rem">
              <span class="span1">{{ _item.key }}</span>
              <span class="span2">{{ _item.value }}</span>
            </div>
          </div>
        </template>
        <t-result v-else style="padding: 20rem 0"></t-result>
      </div>
    </div>


    <div class="button" style="margin-top: 76rem">
      <template v-if="status == 1">
        <el-button :disabled="submitStatus" type="primary" @click="onSearch">提交</el-button>
        <el-button @click="$router.back()">取消</el-button>
      </template>
      <template v-else>
        <el-button @click="$router.back()">返回</el-button>
      </template>
    </div>

  </div>
</template>

<script>
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {Message} from "element-ui";

export default {
  _config: {"route": {"path": "details", "meta": {"title": "详情"}}},
  components: {Editor, Toolbar},
  data() {
    return {
      aaa: '',
      OSS_URL: process.env["VUE_APP_URL_OSS"],
      status: '',
      dataCont: '',
      radio: 1,
      radioConfig: [
        {text: '解决', label: 1},
        {text: '不予解决', label: 2},
      ],
      content: '',
      content1: '',

      toolbarConfig: {
        excludeKeys: [
          'fullScreen',
          'insertVideo'
        ],
      },
      editor: null,
      mode: 'simple', // or 'default',
      editorConfig: {
        placeholder: '请输入内容...',
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 图片上传
          uploadImage: {
            server: process.env.VUE_APP_URL_ONE + '/api/upload/upload',
            fieldName: 'upload',
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 5 * 1024 * 1024, // 5M
            // 最多可上传几个文件，默认为 100
            // maxNumberOfFiles: 1,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['image/*'],
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {},
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,

            // 自定义增加 http  header
            headers: {
              authorization: 'Bearer ' + sessionStorage.getItem('TOKEN')
              // Accept: 'text/x-json',
              // otherKey: 'xxx'
            },

            //自定义上传方法
            customUpload: this.uploadImageMe,

            // 跨域是否传递 cookie ，默认为 false
            withCredentials: false,

            // 超时时间，默认为 10 秒
            timeout: 10 * 1000, //10 秒

            // 上传前
            // onBeforeUpload(files) {
            //   Message({
            //     message: '图片正在上传中,请耐心等待',
            //     duration: 0,
            //     customClass: 'uploadTip',
            //     iconClass: 'el-icon-loading',
            //     showClose: true
            //   });
            //   return files;
            // },

            // 自定义插入图片
            customInsert(res, insertFn) {
              // 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
              // 先关闭等待的Message
              // Message.closeAll();
              if (res.status === 0) {
                Message.success({
                  message: '上传成功'
                });
                insertFn(res.data.file_addr, '', res.data.file_addr);
              } else {
                Message.error({
                  message: res.msg
                });
              }
            },

            // 单个文件上传成功之后
            onSuccess(file, res) {
              // console.log(`${ file.name } 上传成功`, res);
            },

            // 单个文件上传失败
            onFailed(file, res) {
              Message.error('上传失败请重试')
            },

            // 上传进度的回调函数
            onProgress(progress) {
              // progress 是 0-100 的数字
            },

            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
              if (file.size / 1024 / 1024 > 5) Message.error('上传失败,上传文件过大')
              else Message.error('上传失败请重试')
            }
          },
        }
      },

      formData: {
        course: [
          {
            category: '',
            content: '',
          }
        ]
      },

      submitStatus: true
    }
  },

  created() {
    this.status = this.$route.query.status
    this.$_axios2.get('api/question/doubt/pc-details', {
      params: {
        id: this.$route.query.id,
        status: this.status
      }
    }).then(res => {
      this.dataCont = res.data.data;
    })
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },

  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },

    richChange(e) {
      this.checkStatus()
    },

    deleteKnowledge(index) {
      this.formData.course.splice(index, 1)
    },

    addKnowledge() {
      this.formData.course.push({
        category: '',
        content: '',
      })
    },

    checkStatus() {
      let radio = this.radio,
          content = this.content,
          content1 = this.content1,
          course = this.formData.course;
      content = content.replace(/<p>/g, '')
          .replace(/<\/p>/g, '')
          .replace(/<br>/g, '')
          .replace(/&nbsp;/g, '').trim();
      let arr = this.formData.course.filter(item => item.category.trim() || item.content.trim());
      if (radio == 1) {
        this.submitStatus = !(content && arr.length)
      } else {
        this.submitStatus = !content1
      }
    },

    uploadImageMe(file, insertFn) {
      const loading = this.$loading({
        lock: true,
        text: '上传中',
        customClass: 'img-upload-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$uploadToOSS_1(file, 1).then(res => {
        insertFn(res, file.name, res)
      }).finally(() => {
        loading.close()
      })
    },

    onSearch() {
      let content = this.content.replace(/<p>/g, '')
          .replace(/<\/p>/g, '')
          .replace(/<br>/g, '')
          .replace(/&nbsp;/g, '').trim();
      let arr = this.formData.course.filter(item => item.category.trim() || item.content.trim());

      if (this.radio == 1) {
        if (!content) {
          this.$message.error('请输入解决方案！')
          return;
        }
        if (!arr.length) {
          this.$message.error('请至少输入一条关联知识点！')
          return;
        }
      }
      if (this.radio == 2 && !this.content1.trim()) {
        this.$message.error('请输入不予解决原因！')
        return;
      }
      let params = {
        id: this.$route.query.id,
        solve_status: this.radio,
        solve_content: this.radio == 1 ? this.content : this.content1,
        points: this.radio == 1 ? arr : []
      }
      this.$_axios2.post('api/question/doubt/pc-edit', params).then(res => {
        if (res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '提交成功'
          })
          this.$store.commit("setPage", 1);
          this.$router.back();
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title-title {
  font-size: 18rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60rem;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1rem solid #F7F7F7;
  border-radius: 10rem 10rem 0 0;
  background: #FFF;
  box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
}

.title-bottom {
  box-sizing: border-box;
  margin-bottom: 24rem;
  //padding-top: 28rem;
  padding-bottom: 24rem;
  padding-left: 32rem;
  padding-right: 20rem;
  border-radius: 0 0 10rem 10rem;
  background: #FFF;
}

.body {
  margin: 24rem 0;

  & .body-title {
    font-size: 18rem;
    font-weight: bold;
    box-sizing: border-box;
    height: 78rem;
    padding-top: 19rem;
    padding-left: 24rem;
    color: rgba(0, 0, 0, 0.8);
    border-bottom: 1rem solid #F7F7F7;
    border-radius: 10rem 10rem 0 0;
    background: #FFFFFF;
    box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
  }

  & .body-bottom {
    box-sizing: border-box;
    min-height: 148rem;
    padding: 0 24rem 24rem;
    border-radius: 0 0 10rem 10rem;
    background: #FFFFFF;
    box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);

    & .body-bottom-label {
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-size: 14rem;
      font-weight: 400;
      width: 96rem;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.el-table__body .cell {
  white-space: normal;
  word-wrap: break-word;
}

.bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 220rem;
  height: 60rem;
}

.box-1 {
  width: 25%;
}

.box-2 {
  width: 33.3%;
}

.box-3 {
  width: 50%;
}

.box {
  font-size: 14rem;
  color: rgba(0, 0, 0, .6);
  margin-top: 24rem;

  span {
    margin-right: 30rem;
    color: rgba(0, 0, 0, .8);
  }
}

.knowledge {
  margin-top: 10rem;
  display: flex;
  align-items: center;

  .knowledge_box {
    display: flex;
    padding-top: 12rem;
    padding-left: 20rem;
    height: 60rem;
    background-color: #f4f4f4;

    .el-input {
      margin-right: 20rem;
      width: 250rem;

      &:last-child {
        width: 400rem;
      }
    }
  }

  .add_knowledge {
    display: flex;
    padding-left: 40rem;
  }
}

.span3 {
  display: inline-block;
  padding: 6rem 10rem;
  font-size: 14rem;
  border-radius: 6rem;
  background: #f7f7f7;
  line-height: 24rem;
  margin-right: 12rem;
  margin-top: 12rem;
}

.rich-box {
  background: #f7f7f7;
  padding: 12rem;
  border-radius: 6rem;
}

.span1 {
  font-size: 14rem;
  color: rgba(0, 0, 0, .6);
  margin-right: 20rem;
}

.span2 {
  font-size: 14rem;
  color: rgba(0, 0, 0, .8);
}

.describe-box {
  background: #f7f7f7;
  padding: 12rem;
  border-radius: 6rem;
  font-size: 14rem;
  line-height: 26rem;
}

::v-deep .w-e-text-container {
  font-family: "Roboto", sans-serif;
}
</style>
